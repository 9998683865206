import * as React from "react";

function Legal() {
    return (
    <div>
        <p class="micro">
        Fair Value Group is a  ("FVG"), its network of member firms. Each of its member firms are legally separate and independent entities. 
        Fair Value Capital LLC is US Limited Liability Company and does not provide services to clients. 
        Fair Value Capital LLC does not provide financial nor legal advice. <br />
        The information contained herein is of a general nature and is not intended to address the circumstances of any particular individual or entity. 
        Although we endeavor to provide accurate and timely information, there can be no guarantee that such information is accurate as of the date it is received or that it will continue to be accurate in the future. 
        No one should act upon such information without appropriate professional advice after a thorough examination of the particular situation.<br />
        © Fair Value Capital LLC. All rights reserved.</p>
    </div>
    );
  }

export default Legal;