import * as React from "react";
import Slider from "./Slider";

function Home() {
return (
    <>
    <main>
        <h1>Welcome to our homepage!</h1>
        <h2>We are working in our new page</h2>
        <h3>THANKS</h3>
        <p></p>
        <Slider/>
    </main>
    </>
);
}
export default Home;