import * as React from "react";


function BottomNav() {
    return (
        <div className="bottomNav">
            <p>Links</p>
            <a href="https://www.w3schools.com/">Visit W3Schools.com!</a><br />
            <a href="https://www.w3schools.com/">Visit W3Schools.com!</a>   
        </div>
        
    );
  }

export default BottomNav;