import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Slider.css';

function Slider() {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img 
            className="d-block w-100"
            src="https://images.pexels.com/photos/5762281/pexels-photo-5762281.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Tiger"
          />
          <Carousel.Caption>
            <h3>1</h3>
            <p>Clear Goal</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="d-block w-100"
            src="https://images.pexels.com/photos/2923594/pexels-photo-2923594.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Dune"
          />
  
          <Carousel.Caption>
            <h3>2</h3>
            <p>Enjoy the journey</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="d-block w-100"
            src="https://images.pexels.com/photos/573863/pexels-photo-573863.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
            alt="Third slide"
          />
  
          <Carousel.Caption>
            <h3>3</h3>
            <p>
              Oportunity
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }

export default Slider;