import * as React from "react";

function Consulting() {
    return (
        <>
        <p></p>
        <p><h1>Consulting</h1></p>
        <div class="container">
            <div class="row"><div class="col-sm"><p></p></div></div>
            <div class="row">
                <div class="col-sm">
                    <figure className="position-relative">
                        <img src="./img/consulting/consulting_01.jpeg" alt="Consulting" className="img_sized"/>
                        <figcaption class="img_caption">
                            <p></p>
                            <h3>Consulting</h3>
                            <p>Startegy and Execution</p>
                            <p></p>
                        </figcaption>
                    </figure>
                </div>
            </div>
            <div class="row"><div class="col-sm"><p></p></div></div>
        </div>

        <div class="container"><div class="row"><div class="col_spacing"><p></p></div></div></div>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <p></p>
                    <p><h3>Services</h3></p>
                    <img src="./img/consulting/services_01.jpeg" alt="Services" className="img_sized"></img>
                    <p>
                    <p></p>
                        <ul>
                          <li>Corporate Structuring</li>
                          <li>Bookkeeping, Accounting and Tax Reporting</li>
                          <li>Financial and Risk Management</li>
                          <li>Company and Project Valuation</li>
                        </ul>
                    </p>
                </div>
                <div class="col_spacing"></div>
                <div class="col-sm">
                    <p></p>
                    <p><h3>Technology</h3></p>
                    <img src="./img/consulting/technology_01.jpeg" alt="Technology" className="img_sized"></img>
                    <p>
                    <p></p>
                        <ul>
                            <li>Data Automation</li>
                            <li>Business Intelligence</li>
                            <li>AI Modeling</li>
                            <li>Crypto and Web3</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
        </>
    );
  }

export default Consulting;