import * as React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';

/**Top */
import NavToggler from "./components/Main/navbars/TopNav";
/**Main */
import Home from "./components/Home/Home";
import Consulting from "./components/Consulting/Consulting";
import Learning from "./components/Learning/Learning";
import About from "./components/About/About";
/**Bottom */
import BottomNav from "./components/Main/navbars/BottomNav";
import Legal from "./components/Main/Legal"; 

function App() {
  return (
    <div className="App">
      <NavToggler />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="consulting" element={<Consulting />} />
        <Route path="learning" element={<Learning />} />
        <Route path="about" element={<About />} />
      </Routes>
      <p></p>

      <BottomNav />
      <Legal />
    </div>
  );
}

export default App;
