import * as React from "react";
import './TopNav.css';

function NavFixed() {
    async function Message() {
        console.log('Toggle')
         }
    return (
        <>
        <nav class="navbar fixed-top navbar-expand-lg bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img src="./img/main_logo.png" alt="Fair Value" className="main_logo"></img></a>
            <button onClick={Message} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarItems" aria-controls="navbarItems" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarItems">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="/consulting">Consulting</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="/learning">Learning</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="/about">About</a>
                    </li>
                </ul>
            </div>
        </div>
        </nav>
        </>
    );
}
export default NavFixed;