import * as React from "react";

function Learning() {
    return (
        <>
        <p></p>
        <p><h1>Learning</h1></p>

        <div class="container"><div class="row"><div class="col_spacing"><p></p></div></div></div>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <p></p>
                    <p><h3>Personal</h3></p>
                    <img src="./img/consulting/advisory_01.jpeg" alt="Advisory" className="img_sized"></img>
                    <p>
                    <p></p>
                        <ul>
                            <li>Estate Planning</li>
                            <li>Wealth Management</li>
                            <li>Family Office</li>
                        </ul>
                    </p>
                </div>
                <div class="col_spacing">
                </div>
                <div class="col-sm">
                    <p></p>
                    <p><h3>Business</h3></p>
                    <img src="./img/consulting/services_01.jpeg" alt="Services" className="img_sized"></img>
                    <p>
                    <p></p>
                        <ul>
                          <li>Corporate Structuring</li>
                          <li>Bookkeeping, Accounting and Tax Reporting</li>
                          <li>Financial Management</li>
                          <li>Risk Management</li>
                          <li>Company and Project Valuation</li>
                        </ul>
                    </p>
                </div>
                <div class="col_spacing"></div>
                <div class="col-sm">
                    <p></p>
                    <p><h3>Technology</h3></p>
                    <img src="./img/consulting/technology_01.jpeg" alt="Technology" className="img_sized"></img>
                    <p>
                    <p></p>
                        <ul>
                            <li>Data Automation</li>
                            <li>Business Intelligence</li>
                            <li>AI Modeling</li>
                            <li>Crypto and Web3</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
        </>

    );
  }

export default Learning;