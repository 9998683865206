import * as React from "react";

function About() {
    return (
        <div className="About">
            <h1>About Page</h1>

                <main>
                <h2>Who are we?</h2>
                <p>
                    That feels like an existential question, don't you
                    think?
                </p>
                <p>

                </p>
            </main>
        </div>

    );
  }

export default About;